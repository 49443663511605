<template>
  <div class="complete-register">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-8 col-xl-7">
          <div class="content m-y-50 white-color-bg p-y-22 p-x-16 radius-14">
            <h3 class="bold-font fs-18 black-color-text m-b-22">
              {{ $t('forms.personalInfo') }}
            </h3>

            <form @submit.prevent="validateData">
              <BaseTextInput
                :value="form.firstName"
                input-id="firstName"
                :label="$t('forms.name')"
                class="m-b-18"
                :has-error="errors.has('firstName')"
                :message="errors.get('firstName')"
                isRadius
                @reset="errors.clear()"
                @submit="validateData"
                @update-value="getLoginData"
              />

              <BaseTextInput
                :value="form.phone"
                input-id="phone"
                :label="$t('forms.phone')"
                class="m-b-18"
                :has-error="errors.has('phone')"
                :message="errors.get('phone')"
                isRadius
                @reset="errors.clear()"
                @submit="validateData"
                @isNumber="isNumber"
                @update-value="getLoginData"
              />

              <BaseTextInput
                :value="form.email"
                input-id="email"
                :label="$t('forms.email')"
                class="m-b-18"
                :has-error="errors.has('email')"
                :message="errors.get('email')"
                isRadius
                @reset="errors.clear()"
                @submit="validateData"
                @update-value="getLoginData"
              />

              <BaseDateInput
                input-id="birthDate"
                class="m-b-18"
                :label="$t('forms.birthDate')"
                @update-selected="updateDate"
              />

              <div class="row">
                <div
                  v-if="cities.length"
                  class="col-12 col-md-6"
                >
                  <base-dropdown
                    input-id="cities"
                    :options="cities"
                    :value="form.citiesValue"
                    :placeholder="$t('forms.chooseCity')"
                    take-full-width
                    :has-shadow="false"
                    has-border
                    :label="$t('forms.city')"
                    class="m-b-18"
                    @update-seletced="updateCity"
                  />
                </div>
                <div class="col-12 col-md-6">
                  <base-dropdown
                    input-id="districts"
                    :options="districts"
                    :value="form.districtsValue"
                    take-full-width
                    :has-shadow="false"
                    has-border
                    :label="$t('forms.districts')"
                    class="m-b-18"
                    @update-seletced="updateSeletced"
                  />
                </div>
              </div>

              <base-dropdown
                input-id="gender"
                :options="lookups.genderTypes"
                :value="form.gender"
                take-full-width
                :has-shadow="false"
                has-border
                :label="$t('forms.gender')"
                class="m-b-18"
                @update-seletced="updateGender"
              />

              <!-- <BaseTextInput
                :value="form.referralCode"
                input-id="referralCode"
                label="كود الاحاله"
                class="m-b-18"
                :has-error="errors.has('referralCode')"
                :message="errors.get('referralCode')"
                isRadius
                @reset="errors.clear()"
                @submit="validateData"
                @update-value="getLoginData"
              /> -->

              <BaseButton
                color-class="white-color-text"
                bg-color-class="main-color-bg"
                :text="$t('forms.newAccount')"
                is-medium
                @action="completeRegister()"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseDateInput from '@/components/partials/base-date-input/Index.vue'
import Form from '@/services/Form'
import Errors from '@/services/Errors'
import AuthLayout from '@/layouts/AuthLayout'
import { lookups } from '@/static/gender.js'
import { mapGetters } from 'vuex'

export default {
  name: 'CompleteRegister',
  components: {
    AuthLayout,
    BaseDateInput
  },

  data () {
    return {
      form: new Form({
        firstName: '',
        phone: '',
        email: '',
        citiesValue: '',
        districtsValue: '',
        gender: 'male',
        timeValue: ''
      }),
      lookups,
      errors: new Errors(),
      user_id: '',
      city_id: '',
      districtId: ''
    }
  },

  computed: {
    ...mapGetters('locations', ['cities', 'districts'])
  },
  mounted () {
    this.$store.dispatch('locations/getAllCountries')
  },

  methods: {
    updateCity (value) {
      this.city_id = value.value
      this.form.citiesValue = value.value
      this.getDistricts()
    },

    isNumber (event) {
      if (!/\d/.test(event.key) && event.key !== '.') return event.preventDefault()
    },

    updateSeletced (value) {
      this.districtId = value.value
      this.form.districtsValue = value.value
    },

    updateGender (value) {
      this.form.gender = value.value
    },

    updateDate (value) {
      this.form.timeValue = value.value
    },

    completeRegister () {
      delete this.form.originalData
      this.$store
        .dispatch('Globals/completeUserRegister', {
          user_id: this.$store.state.Globals.user_id,
          first_name: this.form.firstName,
          email: this.form.email,
          gender: this.form.gender,
          mobile: this.form.phone,
          users_government: this.city_id,
          users_area: this.districtId,
          users_birthday: this.form.timeValue
        })
        .then(() => {
          // this.$router.push({ name: "Home" });
        })
    },

    getDistricts () {
      const cityPayload = {
        user_id: this.$store.state.Globals.user_id,
        city_id: this.city_id
      }
      this.$store
        .dispatch('locations/getAllDistricts', cityPayload)
        .then(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.complete-register {
  //   height: calc(100vh - 80px);
  display: grid;
  place-items: center;

  .content {
    width: 100%;
    // max-width: 530px;
    box-shadow: 0 4px 14px 0 rgba($black-color, 0.2);
  }
}
</style>
