<template>
  <div class="auth-layout">
    <!-- <div class="navbar d-flex justify-content-start align-items-center">
            <router-link to="/offers/new-offers">new offers</router-link>
            <router-link to="/offers/best-offers">best offers</router-link>
            <router-link to="/offer/1">offer</router-link>
        </div> -->
    <BaseNavbar />
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
import BaseNavbar from '../components/partials/base-navbar/Index.vue'

export default {
  name: 'AuthLayout',
  components: { BaseNavbar }
}
</script>

<style lang="scss" scoped>
.auth-layout {
  .navbar {
    height: 100px;
    width: 100%;
    background-color: #fff;
    gap: 15px;
  }

  .content {
    background-color: #f6f6f6;
    min-height: calc(100vh - 100px);
  }
}
</style>
