<template>
  <AuthLayout>
    <CompleteRegister />
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/layouts/AuthLayout'
import CompleteRegister from '@/components/auth/complete-register/Index.vue'
export default {
  name: 'CompleteRegisterPage',
  components: { AuthLayout, CompleteRegister }
}
</script>
